import React from "react"

import CTA from "../CTA"

type Data = {
  project: string
  bgcolor?: string
}

const PageFooter: React.FC<Data> = ({ project, bgcolor = "#fff" }) => {
  return <CTA />
}

export default PageFooter
