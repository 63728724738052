import React from "react"
import { Container, Row, Col } from "reactstrap"

import Layout from "../components/Layout"
import PageFooter from "../components/PageFooter"
import SEO from "../components/SEO"
import WipeScreen from "../components/WipeScreen"

import { StaticImage } from "gatsby-plugin-image"

const Samedica = () => (
  <>
    <SEO title={"Work | Samedica CRM"} slug={"samedica-crm"} />
    <Row className="white-container case-study">
      <Container className="mt-5 pt-5">
        <Row className="mt-5 pt-5 no-gutters">
          <Col sm="8" className="super-margin">
            <h1>
              Samedica - CRM & Reporting Software for Medical Sales
              Representatives
            </h1>
            <br />
            <p>Case Study</p>
          </Col>
        </Row>
      </Container>
    </Row>
    <Row className="case-study-content">
      <Container
        className="hero-container"
        style={{ background: "#cde4e1", marginBottom: 20 }}
        fluid
        tag="section"
      >
        <div className="work-image">
          <StaticImage
            src="../images/samedica/samedica_1.jpg"
            alt="web_1"
            width={1600}
            quality={100}
            formats={["auto", "webp"]}
          />
        </div>
      </Container>
      <Container
        className="article-content"
        tag="section"
        fluid
        style={{ backgroundColor: "#fff" }}
      >
        <Container>
          <Row className="inter-section">
            <Col sm="12">
              <Col sm="6" className="p-0 section-text float-left">
                <h3>01 Challenge</h3>
                <p>
                  Samedica offers marketing and business services tailored for
                  medical firms operating in the German market. The company
                  faced the challenge of adapting its CRM & Reporting software
                  to efficiently handle the substantial influx of daily sales
                  data. The primary objective was to develop solutions that
                  would enable quick identification of any issues concerning key
                  performance indicators (KPIs) on a daily, weekly, and monthly
                  basis. <br /> <br /> The project required the seamless
                  provision of ongoing sales reporting, task management, and
                  data analysis across various dimensions, ensuring
                  accessibility for both managers and sales representatives as
                  they executed their daily responsibilities.
                </p>
              </Col>
              <Col
                sm={{ size: 4, offset: 2 }}
                className="p-0 section-text float-left"
              >
                <h4>Scope</h4>
                <p className="gray">
                  Product design / UI/UX / Data visualization / Frontend
                  development
                </p>
                <h4>Date</h4>
                <p className="gray">2018 - 2019</p>
                <h4>Industry & location</h4>
                <p className="gray">Healthcare / Frankfurt, Germany</p>
              </Col>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container
        className="imagery-content last-imagery"
        style={{ background: "#cde4e1" }}
        fluid
        tag="section"
      >
        <div className="work-image">
          <StaticImage
            src="../images/samedica/samedica_2.jpg"
            alt="web_1"
            width={1600}
            quality={100}
            formats={["auto", "webp"]}
          />
        </div>
      </Container>
      <Container
        className="imagery-content last-imagery"
        style={{ background: "" }}
        fluid
        tag="section"
      >
        <div className="circles">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <Container>
          <Row align="center" className="mt-5 pt-5 mb-5">
            <Col sm="4">
              <h2>3</h2>
              <p>Team Members</p>
            </Col>
            <Col xs="6" sm="4">
              <h2>6 Mos.</h2>
              <p>Duration</p>
            </Col>
            <Col xs="6" sm="4">
              <h2>2018</h2>
              <p>Launch Date</p>
            </Col>
          </Row>
        </Container>
      </Container>
    </Row>
    <WipeScreen />
    <PageFooter project="samedica" />
  </>
)

Samedica.Layout = Layout
export default Samedica
